<template>
  <div class="r-switch mt-1">
    <label :class="switchLabelClasses" :for="id">
      <input
        :id="id"
        v-model="val"
        :class="switchInputClasses"
        :disabled="disabled"
        :name="name"
        type="checkbox"
        @change="isToggled"
      />
      <span aria-hidden="true" :class="switchHandlerClasses"></span>
    </label>
    <div class="text-error text-sm font-medium">
      {{ errorText }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'RSwitch',

  props: {
    id: {
      type: String,
      required: true,
      default: '',
    },
    name: {
      type: String,
      required: true,
      default: '',
    },
    /**
     * Pre-select the toggle
     */
    checked: Boolean,
    modelValue: Boolean,
    /**
     * Switch color | [ `orange`, `green` ]
     */
    color: {
      type: String,
      default: 'orange',
    },
    disabled: {
      type: Boolean,
    },
    errorText: {
      type: String,
      default: '',
    },
  },

  emits: ['update:modelValue', 'change'],

  data() {
    return {
      val: this.modelValue || this.checked || null,
    };
  },

  computed: {
    switchLabelClasses() {
      let classStr =
        'r-switch cursor-pointer relative h-6 w-10 inline-block rounded-full transition ease-in-out duration-400';

      if (!this.val) {
        classStr += ' bg-gray-300';
      } else if (this.color === 'green') {
        classStr += ' bg-green-500';
      } else {
        classStr += ' bg-rendin-500';
      }

      if (this.disabled) {
        classStr += ' cursor-not-allowed opacity-50';
      }

      return classStr;
    },

    switchInputClasses() {
      let classStr = 'cursor-pointer opacity-0 absolute h-6 w-10 top-0';

      if (this.disabled) {
        classStr += ' cursor-not-allowed';
      }

      return classStr;
    },

    switchHandlerClasses() {
      let classStr =
        'absolute pointer-events-none inline-block h-4 w-4 rounded-full shadow transform ring-0 top-1 left-5 transition ease-in-out duration-200 bg-white outline outline-white';

      if (!this.val) {
        classStr += ' -translate-x-4';
      } else {
        classStr += ' translate-x-0';
      }

      return classStr;
    },
  },

  watch: {
    modelValue: function () {
      this.val = this.modelValue;
    },
  },

  methods: {
    isToggled(event) {
      this.val = event.target.checked;
      /**
       * Change event.
       *
       * @event change
       * @type { Boolean }
       */
      this.$emit('update:modelValue', this.val);
      this.$emit('change', this.val);
    },
  },
};
</script>
